import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import styled from "styled-components/native";
import * as Linking from 'expo-linking';

const guide = `
“동네 앱자보”는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 통신비밀보호법, 전기통신사업법 등 정보통신 서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며,
관련 법령에 따른 개인정보 취급 정책을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
1. 수집하는 개인정보의 항목	
"동네 앱자보"는 서비스 제공을 위해 다음과 같은 개인정보를 수집하고 있습니다.

1) 필수: 단말기의 고유 정보
2) 필수: 단말기의 위치(GPS) 값
3) 방문 일시, 서비스 이용 기록, 접속 로그	

2. 개인정보 수집 방법	
"동네 앱자보"는 서비스 제공을 위해 다음과 같은 방법으로 개인정보를 수집합니다.

1) 단말기의 위치 값을 기반으로 수집
2) 사용자의 활동명을 이용자로 부터 직접 수집

3. 개인정보의 수집 및 이용목적	
"동네 앱자보"는 수집한 정보를 다음과 같은 목적으로 이용합니다.
	
1) 이용자가 서비스를 원활히 이용할 수 있도록 하기 위해
2) 서비스 이용에 따른 이용자 식별 및 부정 이용 방지를 위해	
3) 서비스 이용에 관한 통계 데이터를 작성하기 위해	
4) 이용자가 서비스를 원활이 사용할 수 있게 하기위해	
5) 중요한 공지사항 등을 필요에 따라 공지하기 위해	

4. 개인정보의 제공 및 공유	
"동네 앱자보"는 원칙적으로 이용자의 개인정보를 이용자 사전 동의 없이 외부에 제공하지 않습니다. 다만 아래의 경우에는 예외로 합니다.	

1) 법령의 규정에 따르거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우	

5. 개인정보 자동 수집 장치의 설치/운영 및 그 거부에 관한 사항	
"동네 앱자보" 서비스는 개인정보 자동 수집 장치(쿠키)를 이용하지 않습니다.

  
6. 개인정보의 보유 및 이용 기간
이용자의 개인정보(위치 값)은 기본적으로 24시간이 지나면 게시물이 삭제 됩니다. 
하지만 게시물이 일정 조건을 달성하면 그 위치의 역사로 남기에 누군가의 신고가 있지 않으면 지워지지 않습니다.

7. 개인정보 파기 절차 및 방법	
이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 파기의 절차 및 방법은 다음과 같습니다.

1) 파기 절차	
이용자가 서비스 이용 등을 위해 입력한 정보는 이용 목적이 달성된 후, 내부 방침 및 관련 법령에 따라 일정 기간 저장된 후 파기됩니다. 
해당 개인정보는 법률에 의한 경우가 아니고서는 보관되는 목적 외로 이용되지 않습니다.	
2) 파기 방법	
종이에 출력된 개인정보는 분쇄기로 분쇄하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

8. 개인정보보호 업무 관련 문의처	
본 개인정보 취급 정책에 관해 질문/불만/상담 내용이 있는 경우, 다음 이메일로 문의하실 수 있습니다.
이메일 : clqud@naver.com

9. 적용 범위	
서비스 내 링크 페이지 등 다른 사업자에 의한 개인정보 수집에 대해서는 본 개인정보 취급 정책의 적용 범위가 아닙니다.
  
10. 개인정보 취급 정책의 개정	
현 개인정보 취급 정책의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 '공지사항'을 통해 고지할 것입니다. 
다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있으면 최소 30일 전에 고지합니다.

  
2020년 11월 11일 개인정보 취급 정책 제정




`;
const EULAGuide = `"동네 앱자보"에 오신 것을 환영합니다! 

본 약관은 회원님의 "동네 앱자보" 이용에 적용되며, 아래 설명된 "동네 앱자보" 서비스에 관한 정보를 제공합니다. 회원님이 "동네 앱자보" 계정을 만들거나 "동네 앱자보"을 이용하면 회원님은 본 약관에 동의하는 것입니다. 

"동네 앱자보" 서비스는 Fumaci.에서 회원님에게 제공하는 Fumaci 제품 중 하나입니다. 따라서 본 약관은 회원님과 Fumaci. 사이의 계약을 구성합니다. 

"동네 앱자보" 서비스

당사는 회원님에게 "동네 앱자보" 서비스를 제공하는 것에 동의합니다. 서비스에는 당사가 "동네 앱자보"의 사명을 실현하기 위해 제공하는 모든 "동네 앱자보" 제품, 기능, 앱, 서비스, 기술 및 소프트웨어가 포함됩니다. 회원님이 관심 있는 사람들과 항목을 더 가까이 접할 수 있도록 서비스는 다음과 같은 요소(서비스)로 구성됩니다.

창작, 연결, 커뮤니케이션, 발견 및 공유를 위한 맞춤화된 기회를 제공합니다.
사람들은 다양합니다. 당사는 회원님이 실제로 관심을 가지는 경험을 공유함으로써 관계를 강화하길 원합니다. 따라서 당사는 회원님 및 다른 사람들이 관심 있는 사람과 사항을 파악하는 시스템을 구축하고 파악한 정보를 이용함으로써 회원님에게 중요한 경험을 창작하고, 발견하며, 동참하고, 공유하도록 합니다. 그 일환으로, 회원님 및 다른 사람들이 "동네 앱자보" 안팎에서 하는 활동에 기초하여 회원님이 관심을 가질 만한 콘텐츠, 기능, 제안 및 계정을 더 잘 보여드리고, 회원님에게 "동네 앱자보"을 경험하는 방법을 제안하기도 합니다.
“동네 앱자보” 서비스는 사용자가 만들어가는 컨텐츠로 이루어져 있습니다. 때때로 불쾌한 게시물 및 컨텐츠를(폭력적인, 성적인, 욕설 등…) 마주할 수 있습니다. 당사는 이러한 컨텐츠를 따로 필터링 하지 않고, 사용자의 자발적인 신고로 게시물을 필터링 합니다. 게시물의 “신고” 기능을 이용하여 20명 이상의 사용자가 신고를 하게 된다면, 그 게시물을 삭제됩니다.
긍정적이고 포괄적이며 안전한 환경을 조성합니다.
당사는 "동네 앱자보" 플랫폼의 보안을 유지하기 위해 당사가 보유한 모든 정보(회원님의 정보 포함)를 이용합니다. 또한 당사는 오용 또는 유해 콘텐츠에 관한 정보를 다른 Fumaci 계열사나 사법당국과 공유할 수 있습니다.
성장하고 있는 "동네 앱자보" 커뮤니티에 지속적으로 서비스를 제공하는 데 도움이 되는 기술을 개발하고 사용합니다.
성장하고 있는 "동네 앱자보" 커뮤니티를 위해 정보를 구성하고 분석하는 것이 "동네 앱자보" 서비스의 핵심입니다. "동네 앱자보" 서비스에서 큰 부분을 차지하는 것은 광범위한 글로벌 커뮤니티를 위하여 엄청나게 큰 규모로 "동네 앱자보" 서비스를 맞춤화하고, 보호하며, 향상시키는 데 도움이 되는 첨단 기술을 개발하고 사용하는 것입니다. 인공 지능 및 머신 러닝과 같은 기술은 당사가 "동네 앱자보" 서비스 전체에 복잡한 프로세스를 적용할 수 있게 합니다. 자동화 기술 또한 "동네 앱자보" 서비스의 기능성과 무결성 보장에 기여합니다.
Fumaci 계열사 제품 전반에 걸쳐 일관되고 원활한 경험을 제공합니다.
"동네 앱자보"은 Fumaci 계열사 중 하나로서, Fumaci 계열사들은 보다 우수하고 안전하며 보안이 강화된 서비스를 제공하기 위해 기술, 시스템, 인사이트 및 정보를 공유합니다. 그 정보에는 당사가 보유하는 회원님에 대한 정보가 포함되며, 자세한 내용은 데이터 정책에서 알아보세요. 또한 회원님이 사용하는 Fumaci 계열사 제품과 상호 작용할 수 있는 방법을 제공하며, 회원님이 Fumaci 계열사 제품 전반에서 일관적이고 원활한 경험을 얻을 수 있도록 하는 시스템을 설계했습니다.
"동네 앱자보" 서비스를 위해 안정적인 글로벌 인프라를 보장합니다.


회원님의 약속

"동네 앱자보" 서비스 제공에 대한 당사의 약속에 대해 회원님은 다음과 같은 약속을 해야 합니다.
"동네 앱자보"을 이용할 수 있는 주체. 당사는 "동네 앱자보" 서비스가 가능한 개방적이고 포괄적인 서비스가 되기를 원하지만, 본 서비스가 안전하고, 보안성을 갖추며, 법을 준수하는 서비스가 되기를 원하기도 합니다. 따라서 회원님이 "동네 앱자보" 커뮤니티에 참여하려면 몇 가지 제한 사항을 준수해야 합니다.
만 14세 이상이어야 합니다.
관련 법률에 따라 "동네 앱자보" 서비스를 받는 것이 일부라도 금지된 사람 또는 관련 제재 대상 명단에 있어 결제 관련 "동네 앱자보" 서비스를 이용하는 것이 금지된 사람이 아니어야 합니다.
과거에 당사가 회원님의 계정을 법률 또는 "동네 앱자보" 정책 위반을 이유로 하여 비활성화하지 않았어야 합니다.
유죄가 확정된 성범죄자가 아니어야 합니다.
"동네 앱자보"을 사용할 수 없는 경우. 광범위한 커뮤니티에 안전하고 개방적인 "동네 앱자보" 서비스가 제공되기 위해서는 우리가 모두 각자의 본분을 다해야 합니다.
다른 사람을 사칭하거나 부정확한 정보를 제공하면 안 됩니다.
"동네 앱자보"에서 회원님의 신원을 공개할 필요는 없지만 정확한 최신 정보(등록 정보 포함)를 제공해야 합니다. 또한 본인이 아닌 다른 사람을 사칭해서는 안 되며, 다른 사람의 명시적인 허락 없이 다른 사람의 이름으로 계정을 만들면 안 됩니다.
불법적이거나, 오해의 소지가 있거나, 사기적인 행위 또는 불법적이거나 허가받지 않은 목적을 위한 어떠한 행위도 하면 안 됩니다.
특히 "동네 앱자보" 커뮤니티 가이드라인, "동네 앱자보" 플랫폼 정책, 음악 가이드라인을 포함하여 본 약관 또는 당사 정책을 위반하거나 다른 사람들이 위반하도록 돕거나 조장해서는 안 됩니다. 행위 또는 콘텐츠의 신고가 일정 기준을 초과하면 행위 혹은 콘텐츠가 삭제됩니다.
"동네 앱자보" 서비스의 정상적인 운영을 방해하거나 지장을 주는 어떠한 일도 해서는 안 됩니다.
허가받지 않은 방법으로 계정을 만들거나 정보에 접근하거나 정보를 수집하려 해서는 안 됩니다.
여기에는 당사의 명시적 허락 없이 자동화된 방법으로 계정을 만들거나 정보를 수집하는 것이 포함됩니다.
회원님의 계정 일부(사용자 이름 포함)를 구입, 판매 또는 양도하거나, 다른 사용자의 로그인 정보 또는 배지를 요청, 수집 또는 사용하려고 시도해서는 안 됩니다.
개인 정보 또는 기밀 정보를 게시하거나 지적 재산을 포함한 타인의 권리를 침해하는 어떠한 행위도 해서는 안 됩니다.
여기에서 회원님의 지적 재산권을 침해한다고 생각되는 콘텐츠를 신고하는 방법을 포함한 자세한 내용을 알아보세요.
당사의 사전 서면 동의 없이 회원님의 사용자 이름에 도메인 이름이나 URL을 사용하면 안 됩니다.
회원님이 당사에 부여한 권한. 본 계약의 일부로서, 회원님은 당사가 회원님에게 서비스를 제공하기 위해 필요로 하는 권한을 함께 부여합니다.
당사는 회원님의 콘텐츠에 대한 소유권을 주장하지 않지만 회원님은 당사에 회원님의 콘텐츠를 이용할 라이선스를 부여합니다.
회원님의 콘텐츠에 대한 회원님의 권리에 있어 변경되는 사항은 없습니다. 당사는 회원님이 서비스에 또는 서비스를 통해 게시하는 콘텐츠에 대해 소유권을 주장하지 않습니다. 그 대신 회원님이 지적 재산권(사진 또는 동영상 등)이 적용되는 콘텐츠 또는 당사 서비스와 관련된 콘텐츠를 공유, 게시 또는 업로드할 때 회원님은 콘텐츠를 전 세계적으로 호스팅, 사용, 배포, 수정, 실행, 복사, 공개적으로 수행 또는 표시, 번역 및 파생 저작물을 생성할 수 있는 비독점적이고 양도 가능하며 2차 라이선스를 가질 수 있고 사용료가 없는 라이선스를 당사에 부여합니다(회원님의 개인정보 취급방침 및 애플리케이션 설정과 일치함). 이러한 라이선스는 "동네 앱자보" 서비스에 접속하고 이를 이용하는 회원님 및 다른 사람들에게 "동네 앱자보" 서비스 이용을 가능하게 하기 위한 것입니다. 콘텐츠 또는 계정을 삭제하여 언제든지 이러한 라이선스를 종료할 수 있습니다. 
회원님은 당사가 회원님의 기기에 "동네 앱자보" 서비스 업데이트를 다운로드하고 설치할 수 있다는 것에 동의합니다.

당사가 보유하는 추가적인 권리

회원님의 계정을 위해 사용자 이름 또는 이와 유사한 식별자를 선정할 경우, 당사는 적절하거나 필요하다고 판단될 경우 이를 변경할 수 있습니다(예: 타인의 지적 재산권을 침해하거나 다른 사용자를 사칭하는 경우).
당사가 소유하고 "동네 앱자보" 서비스 내에서 사용하도록 한 지적 재산권의 적용을 받는 콘텐츠(예: 당사로부터 받아 회원님이 생성하거나 공유하는 콘텐츠에 추가되는 사진, 디자인, 동영상 또는 사운드)를 회원님이 사용하는 경우, 당사는 해당 콘텐츠에 대해 모든 권리를 보유하되 회원님 고유의 콘텐츠에 대한 권리를 보유하지는 않습니다.
회원님은 당사의 브랜드 가이드라인이나 사전 서면 허락을 통해 명백하게 허락을 받은 경우에만 당사의 지적 재산권 및 상표 또는 비슷한 상표를 사용할 수 있습니다.
회원님이 당사의 소스코드를 수정하거나, 소스코드에 대한 파생물을 제작하거나, 소스코드를 역컴파일하거나 기타 방법으로 소스코드 추출을 시도하기 위해서는 당사의 서면 허락 또는 오픈 소스 라이선스에 의거한 허락을 받아야 합니다.

콘텐츠 삭제 및 계정 비활성화 또는 해지

회원님이 서비스에서 공유하는 콘텐츠나 정보가 본 약관, 당사 정책("동네 앱자보" 커뮤니티 가이드라인 포함)에 위배된다고 판단되거나 법에 따라 삭제해야 할 경우 삭제될 수 있습니다. 당사는 "동네 앱자보" 커뮤니티 또는 "동네 앱자보" 서비스를 보호하기 위해 또는 회원님이 당사에 위험 또는 법적 문제를 일으키거나, 본 약관 또는 "동네 앱자보" 정책("동네 앱자보" 커뮤니티 가이드라인 포함)을 위반하거나, 다른 사람의 지적 재산권을 반복적으로 침해하거나, 법률에 따라 요구되거나 허용되는 경우에 회원님에게 서비스 전부 또는 일부를 제공하는 것을 즉시 중단하거나 거부할 수 있습니다(회원님의 계정 해지 또는 비활성화 포함). 당사가 회원님의 계정을 비활성화하거나 해지하는 조치를 취할 경우 적절할 때 회원님에게 알려드릴 것입니다. 회원님의 계정이 실수로 해지되었다고 생각되는 경우 또는 회원님의 계정을 비활성화하거나 영구 삭제하고 싶은 경우에는 당사 고객 센터로 문의하세요.
삭제된 콘텐츠는 어떠한 경우에도 복구되지 않습니다.

계약 및 상호 의견이 합치되지 않는 경우

계약.
서비스 음악 사용 역시 당사의 뮤직 가이드라인이 적용되며, 당사 API 사용에는 당사의 플랫폼 정책이 적용됩니다. 회원님이 기타 특정 기능 또는 관련 서비스를 이용하는 경우, 추가 약관에 대해 동의할 기회를 제공할 것이며 추가 약관은 당사와 회원님 간의 합의 일부를 구성합니다. 예를 들어, 결제 기능을 사용할 경우 커뮤니티 결제 약관에 동의해야 합니다. 추가 약관 조항 중 일부가 본 계약과 상충될 경우 추가 약관이 우선합니다.
본 계약의 일부가 이행 불가능한 것으로 판단되더라도 나머지 부분은 계속해서 효력을 갖습니다.
본 계약의 개정이나 포기는 당사가 작성하고 서명한 서면으로만 가능합니다. 본 계약의 한 부분을 이행하지 못하더라도 포기로 간주되지는 않습니다.
당사는 회원님에게 명시적으로 부여된 권한을 제외한 모든 권한을 보유합니다.
본 계약에 따른 권리 보유자.
본 계약은 제삼자에게 권리를 부여하지 않습니다.
본 계약에 따른 회원님의 권리나 의무는 당사의 동의 없이 타인에게 양도할 수 없습니다.
당사의 권리와 의무는 타인에게 양도될 수 있습니다. 이러한 양도는 예를 들어 (인수합병이나 자산매각으로 인해) 당사의 소유권이 변경되는 경우 또는 법률에 의해 이뤄질 수 있습니다.
일이 발생할 경우의 책임자.
당사 서비스는 "있는 그대로" 제공되며, 당사는 서비스가 안전하고 확실하게 제공되거나 항상 완벽하게 작동한다고 보장할 수 없습니다. 관련 법률이 허용하는 한도 내에서 당사는 또한 상업성, 특정 목적을 위한 적합성, 권한 및 비침해성에 대한 묵시적 보증을 포함하되 이에 제한되지 않고 명시적이거나 묵시적인 어떠한 보증도 하지 않습니다.
또한 당사는 사람들의 활동이나 말을 통제하지 않으며, 사람들(또는 회원님)의 활동이나 행동(온라인 또는 오프라인) 또는 콘텐츠(불법 또는 유해 콘텐츠 포함)에 대해 책임지지 않습니다. 또한 당사는 다른 사람이나 기업에서 제공하는 서비스와 기능에 대해서도 책임지지 않습니다. 회원님이 당사 서비스를 통해 이러한 서비스와 기능에 접속하더라도 마찬가지입니다.
"동네 앱자보" 서비스에서 발생하는 모든 사항에 대한 당사의 책임은 법률이 허용하는 최대한도로 제한됩니다. "동네 앱자보" 서비스에 문제가 있는 경우, 당사는 해당 문제가 끼칠 수 있는 모든 가능한 영향을 알 수 없습니다. 회원님은 본 약관으로부터 또는 본 약관과 관련하여 발생하는 수익, 수입, 정보 또는 데이터의 손실이나 파생적, 특수적, 간접적, 처벌적, 징벌적 또는 부수적 손해의 가능성을 알고 있더라도 이에 대해 책임지지 않는다는 것에 동의합니다. 여기에는 당사가 회원님의 콘텐츠, 정보 또는 계정을 삭제할 때도 포함됩니다.

본 약관 업데이트

당사는 "동네 앱자보" 서비스 및 정책을 변경할 수 있고, "동네 앱자보" 서비스 및 정책이 정확하게 반영되도록 하기 위해 본 약관을 변경해야 할 경우가 있습니다. 법적으로 달리 요구되지 않는 한 당사는 본 약관을 변경하기 전에 회원님에게 알리고, 변경된 약관의 효력이 발생되기 최소 30일 전에 회원님에게 이를 검토할 기회를 제공합니다. 변경 발효일 이후에도 회원님이 계속 "동네 앱자보" 서비스를 이용하실 경우, 회원님은 변경된 약관의 적용을 받게 됩니다. 본 약관 또는 업데이트된 약관에 동의하지 않을 경우에는 여기서 회원님의 계정을 삭제하면 됩니다. 
개정: 2020년 12월 02일


`;

const BackView = styled.View`
  flex-direction: row;
`;

const BaseView = styled.View`
  flex: 1;
`;

const TitleText = styled.Text`
  font-size: 30px;
  font-weight: 300;
`;
const SubTitleText = styled.Text`
  font-size: 20px;
  font-weight: 200;
`;
const ContentsText = styled.Text``;

const TitleText2 = styled.Text`
  font-size: 20px;
  font-weight: 300;
`;
const SubTitleText2 = styled.Text`
  font-size: 15px;
  font-weight: 200;
`;
const ContentsText2 = styled.Text`
  font-size: 12px;
`;

export default function App() {
  const [isEULA, setIsEULA] = useState(false);

  Linking.getInitialURL().then((url) => {
    if(url.indexOf("eula") != -1) {
      setIsEULA(true);
    }
    else {
      setIsEULA(false);
    }
  });

  return (isEULA ? 
  <BaseView>
    <TitleText2>FUMACI</TitleText2>
    <TitleText2>동네 앱자보 EULA(End User License Agreement)</TitleText2>
    <SubTitleText2>EULA 사항</SubTitleText2>
    <SubTitleText2>최종 사용자 라이센스 협정 또는 계약</SubTitleText2>
    <ContentsText2>{EULAGuide}</ContentsText2>
  </BaseView> :
  <BaseView>
    <TitleText>FUMACI</TitleText>
    <TitleText>동네 앱자보</TitleText>
    <SubTitleText>개인정보 보호정책</SubTitleText>
    <ContentsText>{guide}</ContentsText>
  </BaseView>);
}
